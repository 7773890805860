/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"

const Columns = ({ cols, children }) => {
  return (
    <div
      className="columns"
      sx={{
        variant: "spacingContainer",
        display: "grid",
        gridTemplateColumns: ab({
          _: "1fr",
          md: Array(cols).fill("1fr").join(" "),
        }),
        gridAutoRows: "1fr",
        gap: "15px",
      }}
    >
      {children}
    </div>
  )
}

Columns.propTypes = {
  cols: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default Columns
