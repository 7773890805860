/** @jsx jsx */
import { AspectRatio, jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"
import { useState, useContext } from "react"

import ModeContext from "../context/mode-context"
import ReactPlayer from "react-player/lazy"

const VideoTeaser = ({ headline, description, video, preview, mode }) => {
  const [playing, setPlaying] = useState(false)

  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme
  const modeKey = mode ? mode : globalMode

  const playIcon = (
    <div
      sx={{
        width: "45px",
        height: "45px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 30 30"
      >
        <g
          id="Gruppe_1766"
          data-name="Gruppe 1766"
          transform="translate(23386 -6247)"
        >
          <circle
            id="Ellipse_1281"
            data-name="Ellipse 1281"
            cx="8"
            cy="8"
            r="8"
            transform="translate(-23379 6254)"
            fill="#fff"
          />
          <path
            id="Icon_material-play-circle"
            data-name="Icon material-play-circle"
            d="M18,3A15,15,0,1,0,33,18,15,15,0,0,0,18,3ZM14.25,24.75V11.25L24.75,18Z"
            transform="translate(-23389 6244)"
            fill="#5a5d5f"
          />
        </g>
      </svg>
    </div>
  )

  return (
    <div
      className="video-teaser"
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <div
        sx={{
          aspectRatio: "16 / 9",
          "& video": {
            display: "block",
          },
        }}
      >
        <ReactPlayer
          url={video.filename}
          width="100%"
          height="100%"
          controls={true}
          light={preview.filename}
          playing={playing}
          playIcon={playIcon}
          onClickPreview={() => setPlaying(true)}
        />
      </div>
      <div
        className="text"
        sx={{
          bg: modeKey
            ? colors.modes[modeKey].primary
            : colors.modes["group"].primary,
          color: "#fff",
          textAlign: "center",
          fontSize: 1,
          p: 3,
          position: "relative",
          flex: 1,
          "& p": {
            marginBottom: 0,
          },
          pb: 3,
        }}
      >
        {headline && (
          <h2
            sx={{
              py: 0,
              m: 0,
              fontWeight: 1,
              hyphens: "auto",
              flex: 1,
              mb: "4px",
            }}
          >
            {headline}
          </h2>
        )}
        {description && <div>{description}</div>}
      </div>
    </div>
  )
}

VideoTeaser.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.object.isRequired,
  preview: PropTypes.object,
  mode: PropTypes.string,
}

export default VideoTeaser
